@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
    font-family: "Roboto", sans-serif;
    background: #fff; /* fallback for old browsers */
}
* {
    margin: 0;
    padding: 0;
}
i {
    margin-right: 10px;
}
.navbar-logo {
    padding: 15px;
    color: #fff;
    pointer-events: none;
}

.navbar-mainbg {
    background-color: #575656;
    padding: 0px;
}
#navbarSupportedContent {
    overflow: hidden;
    position: relative;
}
#navbarSupportedContent ul {
    padding: 0px;
    margin: 0px;
}
#navbarSupportedContent ul li a i {
    margin-right: 10px;
}
#navbarSupportedContent li {
    list-style-type: none;
    float: left;
}
#navbarSupportedContent ul li a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 15px;
    display: block;
    padding: 20px 20px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}
#navbarSupportedContent > ul > li.active > a {
    color: #000000;
    background-color: transparent;
    transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
    content: "\f105";
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 14px;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
    transform: rotate(90deg);
}

.hori-selector {
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #fff;
    bottom: 10px;
}
.hori-selector .right {
    right: -25px;
}
.hori-selector .left {
    left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
    margin: 0 10px;
    content: "";
    position: absolute;
    width: 40px;
    height: 44px;
    border-radius: 50%;
    background-color: #575656;
}
.hori-selector .right:before {
    bottom: 0;
    right: -25px;
}
.hori-selector .left:before {
    bottom: 0;
    left: -25px;
}
@media (max-width: 991px) {
    #navbarSupportedContent ul li a {
        padding: 12px 30px;
    }
    .hori-selector {
        margin-top: 0px;
        margin-left: 10px;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .hori-selector .left,
    .hori-selector .right {
        right: 10px;
    }
    .hori-selector .left {
        top: -25px;
        left: auto;
    }
    .hori-selector .right {
        bottom: -25px;
    }
    .hori-selector .left:before {
        left: -25px;
        top: -25px;
    }
    .hori-selector .right:before {
        bottom: -25px;
        left: -25px;
    }
}
