.saleProd {
    margin-top: 80px;
    width: 60%;
}
.parentDivSaleProd {
    display: flex;
    justify-content: center;
}
.parentDivSaleProd input {
    width: 100%;
    margin: 7px 0;
}
.parentDivSaleProd .select {
    width: 100%;
    margin: 10px 0;
}
