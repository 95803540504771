@import url(https://fonts.googleapis.com/css?family=Roboto);
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: #292929;
}

.App {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b5bebb;
    /* background: #ebf5fc; */
}

.form {
    border-radius: 20px;
    padding: 30px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.1),
        -7px -7px 20px rgba(241, 255, 255, 1);

    align-items: center;
}

.form h1 {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 350px;
}

.input-wrapper label {
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
}

.input-wrapper input {
    background-color: transparent;
    border: unset;
    border-radius: 10px;
    padding: 10px;
    outline: none;
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.1),
        inset -3px -3px 5px rgba(241, 255, 255, 0.5);
}

button {
    margin-top: 20px;
    width: 130px;
    background-color: transparent;
    outline: none;
    color: #292929;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px;
    transition: ease all 250ms;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.1),
        -7px -7px 20px rgb(241, 255, 255);
    border: unset;
}

button:hover {
    cursor: pointer;
    color: #292929;
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.1),
        inset -3px -3px 5px rgba(241, 255, 255, 0.5);
}

.error {
    margin-top: 10px;
    font-weight: bold;
    font-style: italic;
    color: #b60000;
}
.logout {
    color: rgb(255, 255, 255);
    margin: 0 1px 0 25px;
}

table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #575656;
    color: white;
}
.tableDiv {
    margin-top: 50px;
}

.announTable {
    margin-bottom: 200px !important;
}
.divlogo {
    display: flex !important;
}
.logoJpg {
    width: 80px;
}

.aaa {
    display: flex;
}

.tabAdd {
    width: 20%;
    margin: 0 10px;
}
.tabAddBtn {
    width: 40px;
    text-align: center;
    background-color: #a50e24;
    color: white;
    padding: 4px 0;
}
.inpProdVar {
    display: flex;
    align-items: center;
}
.inpProdVar input {
    width: 50%;
    margin: 10px 10px 10px 0;
}
.col-12 {
    padding-left: 0px;
}
.labelProdVar {
    display: flex;
    justify-content: flex-end;
    height: 44px;
    width: 373px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-size: 22px;
    cursor: pointer;
}

.plusBtnVar {
    width: 40px;
    text-align: center;
    background-color: #a50e24;
    color: white;
    padding: 4px 0;
}
.divProdVar {
    display: flex;
    width: 97%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #54667a;
    background-color: #fff;

    border: 1px solid #e9ecef;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #a50e24;
    background-color: #a50e24;
}

.rowmargin {
    margin-right: 10px;
}
.checkLnline {
    margin-left: 15%;
}
.tableProdVar {
    display: flex;
}
.treshVariations {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #fff;
    margin-left: 10px;
    border: 1px solid #e9ecef;
    color: #a50e24;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.spanLine {
    color: #a50e24;
}

.prodSpecInp {
    border: none;
    width: 50px;
}
.divCheck {
    width: 66px;
    margin: 0 5px 10px 0;
}
.instock {
    margin-bottom: 6px;
}
.chackinstock {
    display: flex;
    justify-content: center;
}
.chackinstock input {
    margin-bottom: 5px;
}

.btndel {
    width: 70px;
    margin: 10px;
}

.saleProd {
    margin-top: 80px;
    width: 60%;
}
.parentDivSaleProd {
    display: flex;
    justify-content: center;
}
.parentDivSaleProd input {
    width: 100%;
    margin: 7px 0;
}
.parentDivSaleProd .select {
    width: 100%;
    margin: 10px 0;
}

body {
    font-family: "Roboto", sans-serif;
    background: #fff; /* fallback for old browsers */
}
* {
    margin: 0;
    padding: 0;
}
i {
    margin-right: 10px;
}
.navbar-logo {
    padding: 15px;
    color: #fff;
    pointer-events: none;
}

.navbar-mainbg {
    background-color: #575656;
    padding: 0px;
}
#navbarSupportedContent {
    overflow: hidden;
    position: relative;
}
#navbarSupportedContent ul {
    padding: 0px;
    margin: 0px;
}
#navbarSupportedContent ul li a i {
    margin-right: 10px;
}
#navbarSupportedContent li {
    list-style-type: none;
    float: left;
}
#navbarSupportedContent ul li a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 15px;
    display: block;
    padding: 20px 20px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}
#navbarSupportedContent > ul > li.active > a {
    color: #000000;
    background-color: transparent;
    transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
    content: "\f105";
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 14px;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
    transform: rotate(90deg);
}

.hori-selector {
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #fff;
    bottom: 10px;
}
.hori-selector .right {
    right: -25px;
}
.hori-selector .left {
    left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
    margin: 0 10px;
    content: "";
    position: absolute;
    width: 40px;
    height: 44px;
    border-radius: 50%;
    background-color: #575656;
}
.hori-selector .right:before {
    bottom: 0;
    right: -25px;
}
.hori-selector .left:before {
    bottom: 0;
    left: -25px;
}
@media (max-width: 991px) {
    #navbarSupportedContent ul li a {
        padding: 12px 30px;
    }
    .hori-selector {
        margin-top: 0px;
        margin-left: 10px;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .hori-selector .left,
    .hori-selector .right {
        right: 10px;
    }
    .hori-selector .left {
        top: -25px;
        left: auto;
    }
    .hori-selector .right {
        bottom: -25px;
    }
    .hori-selector .left:before {
        left: -25px;
        top: -25px;
    }
    .hori-selector .right:before {
        bottom: -25px;
        left: -25px;
    }
}

