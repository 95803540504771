.announTable {
    margin-bottom: 200px !important;
}
.divlogo {
    display: flex !important;
}
.logoJpg {
    width: 80px;
}

.aaa {
    display: flex;
}

.tabAdd {
    width: 20%;
    margin: 0 10px;
}
.tabAddBtn {
    width: 40px;
    text-align: center;
    background-color: #a50e24;
    color: white;
    padding: 4px 0;
}
.inpProdVar {
    display: flex;
    align-items: center;
}
.inpProdVar input {
    width: 50%;
    margin: 10px 10px 10px 0;
}
.col-12 {
    padding-left: 0px;
}
.labelProdVar {
    display: flex;
    justify-content: flex-end;
    height: 44px;
    width: 373px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-size: 22px;
    cursor: pointer;
}

.plusBtnVar {
    width: 40px;
    text-align: center;
    background-color: #a50e24;
    color: white;
    padding: 4px 0;
}
.divProdVar {
    display: flex;
    width: 97%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #54667a;
    background-color: #fff;

    border: 1px solid #e9ecef;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #a50e24;
    background-color: #a50e24;
}

.rowmargin {
    margin-right: 10px;
}
.checkLnline {
    margin-left: 15%;
}
.tableProdVar {
    display: flex;
}
.treshVariations {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #fff;
    margin-left: 10px;
    border: 1px solid #e9ecef;
    color: #a50e24;
    border-radius: 4px;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.spanLine {
    color: #a50e24;
}

.prodSpecInp {
    border: none;
    width: 50px;
}
.divCheck {
    width: 66px;
    margin: 0 5px 10px 0;
}
.instock {
    margin-bottom: 6px;
}
.chackinstock {
    display: flex;
    justify-content: center;
}
.chackinstock input {
    margin-bottom: 5px;
}

.btndel {
    width: 70px;
    margin: 10px;
}
